import { Col, Row } from 'antd'
import React from 'react'
import QRCodeGenerator from '../../components/Qrcode'

const QrcodeGeneratorScreen = () => {
  return (
    <Row justify="center">
      <Col span={24} md={8}>
        <QRCodeGenerator />
      </Col>
    </Row>
  )
}

export default QrcodeGeneratorScreen