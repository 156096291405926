import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 652.56px;
    background: #000000;
    border-radius: 30px;
    margin-bottom: .5rem;
`

export const Header = styled.div`
    padding: 15px; 
    display: flex;
    gap: 43px;
    
    svg{
        color: white
    }
    section{
        text-align: center;
    }
    section > p{
        color: white;
        font-size: 48px;
        margin: 0px;
        font-family: Nunito, sans-serif;
        font-weight: 600;
        line-height: 50px;
    }
    section > p:nth-of-type(2) {
        margin-top: -5px;
    }
`
export const NumberContainer = styled.div`
    width: 557.83px;
    height: 74.46px;
    background: white;
    display: flex;
    justify-content: space-around;
    border-radius: 15px 15px 0 0;
    margin-bottom: 18px;

    p.first{
        font-size: 35px;
        line-height: 31.4px;
        font-family: Nunito, sans-serif;
    }
    p.second{
        font-size: 55px;
        line-height: 0px;
        margin-top: 50px;
        font-family: "Panton", sans-serif;
    }
`

export const QRCondeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 557.83px;
    height: 531.4px;
    background: white;
`

export const PoweredBy = styled.p`
    font-family: "Nexa Demo", sans-serif;
    font-size: 54px;
    font-weight: 700;
    line-height: 81.86px;
    text-align: left;
    color: white;
    margin: 10px 0 0 0;
`

export const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 466.11px;
    height: 95.33px;
    background: #FFFFFF;
    border-radius: 15px 15px 0 0;
`