import React, { useEffect, useState } from 'react'
import { BusinessEvent } from '../../../data/models/business-events';
import { makeRequest } from '../../helpers/axios-manipulator';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Descriptions, DescriptionsProps, Image, Modal, Row, Skeleton, Typography } from 'antd';

let items: DescriptionsProps['items'] = [
];
type ModalProps = {
  open: boolean,
  urlImg: string,
  title: string,
  price: string
  status: string,
  sold: boolean,
  dimentions: string,
  year: number,
}

const modalReset = {
  open:false, 
  urlImg: '',
  title: '',
  price: '',
  status: '',
  sold: false,
  dimentions: '',
  year: 0
}

const EventItem = () => {
    const { name } = useParams();
    const [modal, setModal] = useState<ModalProps>(modalReset);
    const [loading, setLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<BusinessEvent[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    
    useEffect(() => {
      const fetchBusinessEvents = async () => {
        try {
          const response = await makeRequest({
            origin: window.location.origin,
            method: 'get',
            url: `/api-business-events/${name}`,
            headers: { },
          });
          items=[
            {
                key: '1',
                label: 'Event name',
                children: response[0].event_name,
              },
              {
                key: '2',
                label: 'Host',
                children: response[0].host,
              },
              {
                key: '3',
                label: 'Start Date',
                children: response[0].start_date,
              },
              {
                key: '4',
                label: 'End Date',
                children: response[0].end_date,
              }
          ]
          setEvents(response);
          setLoading(false)
        } catch (error) {
          setError('Error fetching business events.');
          console.error('Error fetching business events:', error);
        }
      };
  
      fetchBusinessEvents();
    }, []);
    const getArtwork = async (artworkId:string) => {
      try {
        const response = await makeRequest({
          origin: window.location.origin,
          method: 'get',
          url: `/api-artwork/${artworkId}`,
          headers: { },
        });
        setModal({open:true, 
          urlImg: response.image_url,
          title: response.title,
          price: response.final_price,
          status: response.status,
          sold: response.created_by_user_id !== response.current_owner,
          dimentions: `${response.length} x ${response.width} x ${response.depth}  ${response.units}`,
          year: response.year});
        setLoading(false)
      } catch (error) {
        setError('Error fetching business events.');
        console.error('Error fetching business events:', error);
      }
    };
    return (
      <div className='mt-5'>
        <Modal title="Artwork details" 
          open={modal?.open} onOk={() => setModal({...modal, open:false})}
          onCancel={() => setModal({...modal, open:false})}
        footer="">
          <Typography.Title  level={5} style={{ margin: '0 0 10px 0', textAlign: 'center' }}>
            {modal?.title}
          </Typography.Title>
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
            <Image  
                style={{ objectFit: 'cover', maxHeight: '60vh'}}
                src={modal?.urlImg}
            />
          </div>
          <Descriptions title="" items={[
            {
              key: '1',
              label: 'Price',
              children: `$ ${modal?.price}`,
            },
            {
              key: '2',
              label: 'Status',
              children: modal?.status,
            },
            {
              key: '3',
              label: 'Sold',
              children: modal?.sold? 'Yes':'No'
            },
            {
              key: '4',
              label: 'Year created',
              children: modal?.year
            },
            {
              key: '5',
              label: 'Dimentions',
              children: modal?.dimentions
            },
          ]} />
        </Modal>
        <Row gutter={[16, 32]}>
            {loading && 
                <>
                    <Skeleton />
                    <Col span={8}>
                        <Skeleton.Image active={true} style={{ width: 300, height: 300 }} />
                    </Col>
                    <Col span={8}>
                        <Skeleton.Image active={true} style={{ width: 300, height: 300 }} />
                    </Col>
                </>
            }
            {events?
                <>
                    <Descriptions title="Event Info" items={items} />
                    {events.map((e, i)=>(
                        <Col key={i} span={8}>
                            <Image
                              onClick={()=>getArtwork(e.artwork_id)}  
                              preview={false}
                              style={{ objectFit: 'cover', cursor: 'pointer'}}
                              height={300}
                              width={300}
                              src={e.image_url}
                            />
                            <Button onClick={()=>getArtwork(e.artwork_id)} style={{borderRadius: 0, marginTop: '-2px', width: 300, zIndex: 0}} block>Details</Button>
                        </Col>
                    ))}
                </>:
                <div>Carregando</div>  
            }
        </Row>
      </div>
    )
}

export default EventItem