import { Alert, Avatar, Button, Modal, Card, Col, Flex, Row, Typography, Empty, notification, FloatButton, Spin, message, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import UserSelect from '../../components/menu/Form/select/UserSelect'
import { User } from '../../../data/models/user'
import { Artwork } from '../../../data/models/artwork'
import { DeleteOutlined, EditOutlined, LoadingOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons'
import { USER_TYPES } from '../../../constants';
import { makeRequest } from '../../helpers/axios-manipulator'
import Meta from 'antd/es/card/Meta'
import * as S from './styles'
import { getCurrentUtcDate } from '../../helpers/current-utc-date-generator';

const { Text } = Typography;

const DealersScreen = () => {
  const [data, setData] = useState<User>({} as User);
  const [limitMultiplier, setLimitMultiplier] = useState(1);
  const [limit] = useState(100);
  const [maxData, setMaxData] = useState(0);
  const [scrollLoading, setScrollLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editContractModal, setEditContractModal] = useState(false);
  const [unlinkContractModal, setUnlinkContractModal] = useState(false);
  const [artworks, setArtworks] = useState([] as Artwork[])
  const [selectedArtworks, setSelectedArtworks] = useState([] as Artwork[])
  const [selectedEditArtwork, setSelectedEditArtwork] = useState({} as Artwork)
  const [openFloatedBtn, setOpenFloatedBtn] = useState<{ [key: number]: boolean }>({})

  const [api, contextHolder] = notification.useNotification();

  const getArtworks = async (delaerId?: string, newlimitMultiplier?: number, isScrolling?: boolean) => {
    if (!isScrolling)
      setLoading(true);
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/api-dealer-artworks/${delaerId || data.id}?page=1&limit=${limit * (newlimitMultiplier ? newlimitMultiplier : limitMultiplier)}`,
        headers: {},
      });
      setArtworks(response.artworks);
      setMaxData(response.total);
      setLoading(false);
      setScrollLoading(false);
    } catch (error) {
      console.error('Error fetching Artworks for dealer:', error);
    }
  };

  function toggleArtworkInArray(array: Artwork[], art: Artwork) {
    return array.some(item => item.id === art.id)
      ? array.filter(item => item.id !== art.id)
      : [...array, art];
  }

  const toggleArtwork = async (art: Artwork) => {
    setSelectedArtworks((prevState) => toggleArtworkInArray(prevState, art));
  }

  const selectAll = () => {
    setSelectedArtworks(
      artworks
        .filter((art) => !art.contract_data.hasRepRegistry)
    );
  }
  const deselectAll = () => {
    setSelectedArtworks([])
  }

  const generateNewContracts = async () => {
    const contracts = selectedArtworks.map((art) => ({
      artist_id: art.current_owner,
      artwork_id: art.id,
      rep_id: data.id,
      rep_type: 4,
      status: 'active',
      commission: art.contract_data.fee,
      creation_date: getCurrentUtcDate(),
      last_updated_date: getCurrentUtcDate(),
      last_updated_by: data.id
    }));
    setLoading(true);
    try {
      await makeRequest({
        origin: window.location.origin,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        url: '/api-manipulate-artwork-contracts',
        data: { contracts },
      });
      message.success('Contracts created with success!');
      setLoading(false)
      getArtworks()
    } catch (error) {
      message.error('something went wrong during the creation')
      setLoading(false)
    }
    setSelectedArtworks([])
  }


  const unlinkContract = async () => {
    setLoading(true);
    setUnlinkContractModal(false)
    try {
      const responseOld = await makeRequest({
        origin: window.location.origin,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/api-update-art-x-rep`,
        data: {
          contract: {
            status: 'inactive',
            commission: selectedEditArtwork.contract_data.fee,
            last_updated_Date: getCurrentUtcDate(),
            last_updated_by: data.id,
            artwork_id: selectedEditArtwork.id
          }
        },
      });
      getArtworks();
      setSelectedEditArtwork({} as Artwork)
      setLoading(false);
      setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: !prev[selectedEditArtwork.id] }))
      api.success({
        message: 'Success!',
        description:
          `Artwork ${selectedEditArtwork.title} unlinked with success!`,
      });
    } catch (error) {
      api.error({
        message: 'Error!',
        description:
          'something went wrong during the unlink',
      });
      setLoading(false);
    }
  };

  const saveEditedContract = async () => {
    setLoading(true);
    try {
      const responseOld = await makeRequest({
        origin: window.location.origin,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/api-update-art-x-rep`,
        data: {
          contract: {
            status: selectedEditArtwork.contract_data.status,
            commission: selectedEditArtwork.contract_data.fee,
            last_updated_Date: getCurrentUtcDate(),
            last_updated_by: data.id,
            artwork_id: selectedEditArtwork.id
          }
        },
      });
      api.success({
        message: 'Success!',
        description:
          `Contract from Artwork ${selectedEditArtwork.title} updated with success!`,
      });
      getArtworks();
      setSelectedArtworks([])
      setSelectedEditArtwork({} as Artwork)
      setLoading(false);
      setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: false }))
      setEditContractModal(false)
    } catch (error) {
      api.error({
        message: 'Error!',
        description:
          'something went wrong during the update',
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setSelectedArtworks([])
      setLimitMultiplier(1)
      getArtworks(undefined, 1)
    }
  }, [data])

  const handleScroll = (dealerId, updatedMaxData) => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.documentElement.scrollHeight - 200;
    if (scrollPosition >= threshold && !scrollLoading && data && artworks.length < updatedMaxData) {
      setScrollLoading(true)
      setLimitMultiplier(limitMultiplier + 1)
      getArtworks(dealerId, limitMultiplier + 1, true);
    }
  };

  useEffect(() => {
    if (data) {
      const debouncedScroll = debounce(() => handleScroll(data.id, maxData), 200);
      window.addEventListener('scroll', debouncedScroll);
      return () => window.removeEventListener('scroll', debouncedScroll);
    }
  }, [data, artworks, maxData]);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  return (
    <S.DealersContainer>
      {loading &&
        <div className="layout-overlay" ><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>
      }
      {/* {selectedArtworks.length > 0 &&
        <h1>Quantity selected: {selectedArtworks.length}</h1>
      } */}
      {contextHolder}
      <Flex align="center" gap="middle" vertical>
        {(data && Object.keys(data).length > 0) ?
          <>
            <img style={{ objectFit: 'cover' }} width={80} height={80}
              src={data.profile_image_url || ''} alt={data.profile_image_url || data.username || ''}
            />
            <div>
              <Flex align='end' justify='center' gap="small">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Username:
                </Typography.Title>
                {data.username}
              </Flex>
              <Flex align='end' justify='center' gap="small">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Profile name:
                </Typography.Title>
                {data.profile_name}
              </Flex>
              <Flex align='end' justify='center' gap="small">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Type:
                </Typography.Title>
                {USER_TYPES[data.type]}
              </Flex>
            </div>
          </> :
          <Avatar shape="square" size={100} icon={<UserOutlined />} />
        }
        <UserSelect className='mb-5' setData={setData} data={data} width='300px' route="/api-users?type=4" />
      </Flex>
      <Row gutter={[16, 24]} className='dealer-artwork-cards'>
        {artworks && Object.keys(artworks).length > 0 ?
          <>
            <Col span={24}>
              <Flex justify="space-between" gap="middle" >
                <div>
                  <Button type="primary" onClick={selectAll} style={{ marginRight: '8px', marginBottom: '.5rem', width: '100px' }}>Select All</Button>
                  {selectedArtworks.length > 0 &&
                    <Button style={{ width: '100px' }} onClick={deselectAll}>deselect all</Button>
                  }
                </div>
                <div>
                  {selectedArtworks.length >= 1 &&
                    <Button size='large' type="primary" className='mr-2'
                      onClick={() => { generateNewContracts() }}
                    >assign rep{selectedArtworks.length > 1 ? 's' : ''}</Button>
                  }
                </div>
              </Flex>
            </Col>
            {artworks.map((art) => (
              <Col className="gutter-row" span={24} md={12} lg={6} xl={artworks.length < 6 ? 6 : 4}>
                <Card
                  onClick={() => !art.contract_data.hasRepRegistry && toggleArtwork(art)}
                  hoverable={!art.contract_data.hasRepRegistry}
                  style={{ height: '100%' }}
                  cover={<img style={{ objectFit: 'cover', height: '130px' }} src={art.image_url || ''} alt={art.title || ''} />}
                >
                  <Meta title={art.title} />
                  <Text type={`${art.is_sale_enabled ? 'success' : 'warning'}`}>{art.is_sale_enabled ? 'Sale Enabled' : 'Not for Sale'}</Text>

                  {art.contract_data.hasRepRegistry ?
                    <p className='my-0 flex flex-col'>
                      <Text strong>Commission:
                      </Text>
                      <p style={{ margin: 0, display: 'contents' }}>{art.contract_data.fee}</p>
                      <div>
                        <Text strong>Status:
                        </Text>
                        <span style={{ margin: 0, display: 'contents' }}> {art.contract_data.status}</span>
                      </div>
                      {/* <MoreOutlined style={{fontSize: '18px', marginLeft: 'auto', cursor: 'pointer'}} /> */}
                      <>
                        <FloatButton.Group
                          className='styled-floated-button'
                          open={openFloatedBtn[art.id]}
                          trigger="click"
                          style={{ position: 'absolute', right: '-2px', bottom: '10px' }}
                          icon={<MoreOutlined />}
                          onClick={() => setOpenFloatedBtn((prev) => ({ ...prev, [art.id]: !prev[art.id] }))}
                        >
                          <FloatButton style={{ width: '30px', height: '30px' }}
                            onClick={() => {
                              setSelectedEditArtwork(art);
                              setUnlinkContractModal(true);
                              setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: !prev[selectedEditArtwork.id] }));
                            }}
                            icon={<DeleteOutlined />} />
                          <FloatButton style={{ width: '30px', height: '30px' }}
                            onClick={() => {
                              setSelectedEditArtwork(art);
                              setEditContractModal(true);
                              setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: !prev[selectedEditArtwork.id] }))
                            }}
                            icon={<EditOutlined />} />
                        </FloatButton.Group>
                      </>
                    </p> :
                    <Empty imageStyle={{ height: '80px' }} description="No contract found" />
                  }
                </Card>
                {selectedArtworks.find((a) => a.id === art.id) &&
                  <div className={`flex flex-col m-auto absolute ${selectedArtworks.find((a) => a.id === art.id) && 'bg-overlay-500'} hover:bg-overlay-500 h-full top-0 rounded-lg z-10 cursor-pointer justify-center items-center desktop`}
                    onClick={() => toggleArtwork(art)} style={{ width: 'calc(100% - 16px)', left: '8px', borderRadius: '6px 6px 0 0', background: '#383838bf' }}>
                    <Alert
                      message="Selected"
                      type="success"
                      showIcon
                    />
                  </div>
                }
              </Col>
            ))}
            {scrollLoading &&
              <div className='flex w-full'>
                <Spin style={{ margin: 'auto', marginTop: '1rem' }} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} size="large" />
              </div>
            }
          </>
          :
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Empty style={{ marginTop: '3rem' }} />
          </div>
        }
        {/* Edit Contract modal */}
        {editContractModal &&
          <Modal
            centered
            title="Edit Contract"
            open={editContractModal}
            okText="Save"
            onOk={saveEditedContract}
            onCancel={() => { setEditContractModal(false) }}
            width={375}
          >
            <Row gutter={8}>
              <Col span={24} md={12}>
                <Text>commission value %</Text>
                <Input className='mb-5' value={selectedEditArtwork?.contract_data?.fee || ''}
                  onChange={(e) => setSelectedEditArtwork({ ...selectedEditArtwork, contract_data: { ...selectedEditArtwork?.contract_data, fee: parseInt(e.target.value) } })}
                  placeholder="Ex: 10" />
              </Col>
            </Row>
          </Modal>
        }
        {/* Delete Contract Modal */}
        <Modal
          centered
          title="Unlink Artwork"
          open={unlinkContractModal}
          onOk={() => unlinkContract()}
          onCancel={() => setUnlinkContractModal(false)}
          okText="Unlink"
          cancelText="Cancel"
        >
          <p>Are you sure that you want to unlink the dealer <b>{data.username}</b> from the artwork <b>{selectedEditArtwork.title}</b></p>
        </Modal>
      </Row>
    </S.DealersContainer>
  )
}

export default DealersScreen