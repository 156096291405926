import { useEffect, useState } from 'react'
import { User } from '../../../data/models/user';
import { Billing } from '../../../data/models/billing';
import UserSelect from '../../components/menu/Form/select/UserSelect';
import { App, Button, Col, Divider, Empty, Input, notification, Progress, Row, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { makeRequest } from '../../helpers/axios-manipulator';
import { getCurrentUtcDate } from '../../helpers/current-utc-date-generator'
import React from 'react';
import Title from 'antd/es/typography/Title';
import { USER_TYPES } from '../../../constants';
import RepresentationContract from '../../components/Billing/RepresentationContract';
import { ArtistRep } from '../../../data/models/artist-rep';
import { green, red, orange } from '@ant-design/colors';
const { Text } = Typography;

const Users = () => {
  const [data, setData] = useState<User>({} as User);
  const [contracts, setContracts] = useState<ArtistRep[]>([]);
  const [billing, setBilling] = useState<Billing>({} as Billing);
  const [newSelectedUser, setNewSelectedUser] = useState<string>()
  const [contractsKey, setContractsKey] = useState<number>(1)

  const contractsPercent = contracts.reduce((sum, contract) => sum + contract.fee, 0);

  const [hasNewContract, setHasNewContract] = useState(false)
  const isAnyEditing = contracts.some(contract => contract.editing === true);
  const [loading, setLoading] = useState(false);

  const { message } = App.useApp();
  const [api, contextHolder] = notification.useNotification();

  const save = async () => {
    const hasDuplicateUsername = contracts.some((contract, index, array) => {
      return array.findIndex(c => c.rep_id === contract.rep_id) !== index;
    });
    if (data?.username?.length && data?.username?.length > 20)
      return message.error('username must have a maximum of 20 characters')
    if (hasDuplicateUsername)
      return api['error']({
        duration: 5,
        message: 'Error creating contract',
        description:
          'It is not possible to add the same dealer more than once',
      });
    setLoading(true)

    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/api-user/${data?.id}`,
        data: { user: data },
      });
      setData(response);
      setNewSelectedUser(response.username)
      message.success('User updated with success!');
      if (data.type === 1 && contracts) {
        const contractsResponse = await makeRequest({
          origin: window.location.origin,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          url: '/api-manipulate-contracts',
          data: { contracts },
        });
        setContracts(contractsResponse)
        message.success('Contracts created with success!');
      }
      if (billing) {
        const response = await makeRequest({
          origin: window.location.origin,
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
          },
          url: `/api-update-billing`,
          data: { billing },
        });
        setBilling(response)
      }
      setLoading(false)
    } catch (error) {
      message.error('something went wrong during the update')
      setLoading(false)
    }
  }

  const addEmptyContract = () => {
    const newContract = {
      id: "",
      artist_id: data?.id,
      dealer_profile: "",
      dealer_username: "",
      rep_id: "",
      rep_type: 4,
      status: "active",
      fee: 0,
      creation_date: getCurrentUtcDate(),
      last_updated_Date: getCurrentUtcDate(),
      last_updated_by: data?.id,
      editing: true
    };
    setContracts(prevContracts => [...prevContracts, newContract]);
    setHasNewContract(true)
  };

  const getRelatedContracts = async () => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/api-available-contracts/${data?.id}`,
        headers: {},
      });
      setContracts(response)
    } catch (error) {

    }
  }

  const getBilling = async () => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/api-billing/${data?.id}`,
        headers: {},
      });
      setBilling(response)
    } catch (error) {

    }
  }

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      getRelatedContracts();
      if (data?.type === 4) getBilling();
      setHasNewContract(false);
    }
  }, [data])

  useEffect(() => { setContractsKey(contractsKey + 1) }, [contracts])


  return (
    <>
      {contextHolder}
      <UserSelect update={loading} className='mb-5' setData={setData} data={data} selectedUser={newSelectedUser} />

      <Text>User Type</Text>
      <Input disabled className='mb-5' value={USER_TYPES[data?.type]}
        placeholder="User type" />

      <Text>Username</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.username || ''}
        count={{
          show: true,
          max: 20,
        }}
        onChange={(e) => setData({ ...data, username: e.target.value })}
        placeholder="Username" />

      <Text>Display name</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.display_name || ''}
        onChange={(e) => setData({ ...data, display_name: e.target.value })}
        placeholder="Display name" />

      <Text>wallet address</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.wallet_address || ''}
        onChange={(e) => setData({ ...data, wallet_address: e.target.value })}
        placeholder="wallet address" />

      <Text>google id</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.google_id || ''}
        onChange={(e) => setData({ ...data, google_id: e.target.value })}
        placeholder="google id" />

      <Text>profile name</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.profile_name || ''}
        onChange={(e) => setData({ ...data, profile_name: e.target.value })}
        placeholder="profile name" />

      <Text>stripe id</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.stripe_id_acct || ''}
        onChange={(e) => setData({ ...data, stripe_id_acct: e.target.value })}
        placeholder="stripe id" />

      <Text>Account id</Text>
      <Input disabled={data && Object.keys(data)?.length === 0} className='mb-5' value={data?.id || ''}
        onChange={(e) => setData({ ...data, id: e.target.value })}
        placeholder="Account id" />

      {data && data?.type === 4 &&
        <>
          <Text>business premium</Text>
          <Input
            disabled={!billing?.premium}
            className='mb-5'
            value={billing?.premium || ''}
            onChange={(e) => setBilling({ ...billing, premium: parseInt(e.target.value) })}
          />

          <Divider className="my-0" orientation="left" orientationMargin="0">
            <Text strong>escrow account</Text> - <Text type={billing?.is_escrow_enabled ? "success" : "danger"}>{billing?.is_escrow_enabled ? "Active" : "Inactive"}</Text>
          </Divider>
          <Row gutter={8}>
            <Col span={24} md={12}>
              <Text>escrow id</Text>
              <Input disabled className='mb-5' value={billing?.escrow_id || ''} />
            </Col>
            <Col span={24} md={12}>
              <Text>escrow premium</Text>
              <Input disabled className='mb-5' value={billing?.escrow_premium || ''} />
            </Col>
          </Row>
        </>
      }

      {data && data?.type === 1 &&
        <>
          <Row>
            <Col span={12}>
              <Title className='mt-0' level={4}>Contracts</Title>
              <Progress percent={contractsPercent} steps={10}
                status="active"
                strokeColor={[green[2], green[3], green[4], orange[3], orange[4], orange[5], red[4], red[5], red[6], red[7]]}
              />
              <p className='mt-0'>
                <Text className='mt-0' type="secondary">artist's committed percentage</Text>
              </p>
            </Col>
            <Col span={12}>
            </Col>
          </Row >
          {
            Object.keys(contracts).length === 0 ? <Empty
              imageStyle={{ height: 60 }}
              description={
                <Typography.Text>
                  no contract found
                </Typography.Text>
              }
            >
              <Button onClick={addEmptyContract}>Create Now</Button>
            </Empty> :
              <div key={contractsKey}>
                {!hasNewContract &&
                  <Button
                    className='mt-4'
                    type="primary" onClick={addEmptyContract}>Create contract</Button>
                }
                {contracts?.map((contract, i) => (
                  <RepresentationContract
                    contract={contract}
                    contracts={contracts}
                    setContracts={setContracts}
                    index={i}
                    hasNewContract={hasNewContract}
                    setHasNewContract={setHasNewContract}
                    loading={false}
                    totalUsedFee={contractsPercent} />
                ))}
              </div>
          }
        </>
      }

      <Button
        className='mt-4'
        disabled={(data && Object.keys(data)?.length === 0) || hasNewContract || isAnyEditing}
        type="primary"
        icon={<SaveOutlined />}
        loading={loading}
        onClick={() => save()}
      >
        Save
      </Button>
    </>
  )
}

export default Users