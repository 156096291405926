import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './views/login';
import { useDispatch, useSelector } from 'react-redux';
import { App as Application } from 'antd';
import { RootState } from '../redux/appState';
import { isEmptyObject } from './helpers/empty-objects-validator';
import { setUser } from '../redux/userSlicer';
import PrivateLayout from './views/PrivateLayout';
import { CustomConfigProvider as ConfigProvider } from '../configProvider';
import Home from './views/Home/Home';
import Events from './views/Events/Events';
import { ReactNode, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { User } from '../data/models/user';
import EventItem from './views/Events/EventItem';
import Users from './views/Users/Users';
import QrcodeGeneratorScreen from './views/QrcodeGenerator/QrcodeGeneratorScreen';
import Loader from './components/Loader/Loader';
import React from 'react';
import PlacardsScreen from './views/Placards/PlacardsScreen';
import DealersScreen from './views/Dealers/DealersScreen';
import Onboarding from './views/Onboarding/Onboarding';

type Props = {
  children: ReactNode
}

const isRootUser = (id, username) => {
  if( username === 'Root' && id === '0' ) {
    return true;
  }

  return false;
}

function CheckLayout({ children }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state: RootState) => state.user.user);
  const isLogged = !isEmptyObject(user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkUserLogin = async () => {
      try {
        // Inicia o carregamento
        setIsLoading(true);

        if (!isLogged) {
          const userDataString = Cookies.get('user');
          if (userDataString) {
            const userData: User = JSON.parse(userDataString);
            dispatch(setUser(userData));
            navigate(location.pathname);
          } else {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error checking user login:', error);
      } finally {
        // Finaliza o carregamento após a conclusão da verificação
        setIsLoading(false);
      }
    };

    // Executa a verificação do login
    checkUserLogin();
  }, [dispatch, isLogged, location.pathname, navigate]);

  return isLoading ? <Loader /> : isLogged ? <PrivateLayout user={user}>{children}</PrivateLayout> : <div style={{ minHeight: '100vh' }}>{children}</div>;
}

const App = () => {
  const currentPath = window.location.pathname;
  const user = useSelector((state: RootState) => state.user.user);
  const cookieText = Cookies.get('user');
  const userFromCookies = cookieText ? JSON.parse(cookieText) : null;
  const defaultForNonRootUsers = <PlacardsScreen />

  return (
    <ConfigProvider>
      <Application>
        <Router>
          <CheckLayout>
            <Routes>
              <Route
                path="/*"
                element={
                  <Routes>
                    <Route path="/" element={ isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <Home /> : defaultForNonRootUsers } />
                    <Route path="/dashboard" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <Home /> : defaultForNonRootUsers } />
                    <Route path="/events" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <Events /> : defaultForNonRootUsers } />
                    <Route path="/events/:name" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <EventItem /> : defaultForNonRootUsers } />
                    <Route path="/users" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <Users /> : defaultForNonRootUsers } />
                    <Route path="/qrcode-generator" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <QrcodeGeneratorScreen /> : defaultForNonRootUsers } />
                    <Route path="/placards" element={<PlacardsScreen />} />
                    <Route path="/dealers" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <DealersScreen /> : defaultForNonRootUsers } />
                    <Route path="/onboarding" element={isRootUser(userFromCookies?.id, userFromCookies?.profile_name) ? <Onboarding /> : defaultForNonRootUsers } />
                  </Routes>
                }
              />
              <Route
                path="/login"
                element={
                  isEmptyObject(user) ? (
                    <Login onLogin={(userData) => setUser(userData)} />
                  ) : (
                    <Navigate to={currentPath === '/login' ? '/' : currentPath} replace />
                  )
                }
              />
            </Routes>
          </CheckLayout>
        </Router>
      </Application>
    </ConfigProvider>
  );
};

export default App;
