import { ConfigProvider } from "antd";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { SystemConfigState } from "./redux/systemConfigSlicer";
import React from "react";

interface CustomConfigProviderProps {
    children: ReactNode;
  }
  
  export const CustomConfigProvider: React.FC<CustomConfigProviderProps> = ({ children }) => {
    const {primaryColor, secondaryColor} = useSelector((state: { systemConfig: SystemConfigState }) => state.systemConfig);
  
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: primaryColor,
            borderRadius: 5,
          },
          components:{
            Menu:{
                itemBg: "#15013A",
                itemColor: '#fff',
                itemSelectedColor:primaryColor,
                itemActiveBg: secondaryColor,
                controlItemBgActive: secondaryColor,
                itemHoverColor: secondaryColor
            },
            Button:{
              defaultColor: primaryColor,
              defaultShadow: '',
              primaryShadow: ''
            },
            Layout:{
                headerBg: secondaryColor,
                siderBg: primaryColor,
                triggerBg: secondaryColor,
                triggerColor: primaryColor
            }
          }
        }}
      >
        {children}
      </ConfigProvider>
    );
  };
