
import { useEffect, useState } from 'react'
import { makeRequest } from '../../helpers/axios-manipulator';
import { Button, Card, Col, Empty, Row, Skeleton } from 'antd';
import { BusinessEvent } from '../../../data/models/business-events';
import {  useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import CreateModal from '../../components/Event/CreateModal';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import React from 'react';

function dateFormatter(date: Date){
  const originalDate = dayjs(date);
return originalDate.format("MM/DD/YYYY");
}

const Events = () => {
  const [events, setEvents] = useState<BusinessEvent[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchBusinessEvents = async () => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: '/api-business-events',
        headers: { },
      });
      setEvents(response);
      setLoading(false)
    } catch (error) {
      setError('Error fetching business events.');
      console.error('Error fetching business events:', error);
    }
  };
  useEffect(() => {
    fetchBusinessEvents();
  }, []);

  const CreateBusinessEvent = async (data:any) => {
    const arrayArtworks = data.artworks.map((art : string)=>({id: art}))
    setOpenModal(false)
    const formattedDates: [Date, Date] = [data.date[0].toDate(), data.date[1].toDate()];
    setLoading(true)
    const usedData = JSON.stringify({event: {
      event_name: data.name, host: data.host, start_date: formattedDates[0], end_date: formattedDates[1], endpoint: data.endpoint
    } , artworks:arrayArtworks})
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        url: '/api-business-events/create',
        data: usedData,
      });
      setEvents(response);
      fetchBusinessEvents()
      setLoading(false)
    } catch (error) {
      setError('Error fetching business events.');
      console.error('Error fetching business events:', error);
    }
  };
  return (
    <div className='mt-5' style={{height: '100%'}}>
      {openModal && 
        <CreateModal open={openModal} save={CreateBusinessEvent} cancel={()=>setOpenModal(false)} />
      }
      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem'}}>
        <Button onClick={()=>setOpenModal(true)} type="primary" icon={<PlusOutlined />}>
          Create event
        </Button>
      </div>
      <Row gutter={[16, 16]} justify={`${!loading && events?.length === 0? "center":"start"}`} align={`${!loading && events?.length === 0? "middle":"top"}`} style={{height: '100%', alignContent:'flex-start'}}>
      {loading?
        <>
          <Col span={24} md={12} lg={8}>
            <Skeleton.Image className='mobile-skeleton-image' active={true} style={{ width: 260, height: 250 }} />
          </Col>
          <Col span={24} md={12} lg={8}>
            <Skeleton.Image className='mobile-skeleton-image' active={true} style={{ width: 260, height: 250 }} />
          </Col>
          <Col span={24} md={12} lg={8}>
            <Skeleton.Image className='mobile-skeleton-image' active={true} style={{ width: 260, height: 250 }} />
          </Col>
        </>:
        <>
          {events.length > 0 ? events.map((e, i) =>(
            <Col key={i} span={24} md={12} lg={8}>
                <Card title={e.event_name} bordered={false} style={{ cursor: 'pointer' }} onClick={()=> navigate(`/events/${e.endpoint}`)}>
                  <p>Host name: {e.host}</p>
                  <p>Start date: {dateFormatter(e.start_date)}</p>
                  <p>End date: {dateFormatter(e.end_date)}</p>
                </Card>
            </Col>
          )):
          <Empty />}
        </>
      }
      </Row>
    </div>
  )
}

export default Events