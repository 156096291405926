import { DatePicker, Form, Input, Modal } from 'antd'
import { useState } from 'react'
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import ArtworkSelect from '../menu/Form/select/ArtworkSelect';
import React from 'react';

const { RangePicker } = DatePicker;

type Props = {
    open:boolean,
    save: Function,
    cancel: ()=>void
}

type dataProps = {
    name: string,
    host: string,
    endpoint: string,
    date?: any 
    artworks?: [] | null
}
const initialData = {
    name: '',
    host: '',
    endpoint: '',
    date: null,
    artworks: null
}
const CreateModal = ({open, save, cancel}:Props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState<dataProps>(initialData)
    function onCalendarChange(dates:any) {
        if(!dates) return
        const formattedDates: [dayjs.Dayjs, dayjs.Dayjs] = [dayjs(dates[0]), dayjs(dates[1])];
        setData({ ...data, date: formattedDates });
    }

      const submit = async () => {
        try {
          const values = await form.validateFields();
          save(data)
        } catch (errorInfo) {
          console.log('Failed:', errorInfo);
        }
      };

  return (
    <Modal maskClosable={false} okText="Save" title="event creation modal" open={open} onOk={()=>submit()} onCancel={cancel}>
        <Form form={form} name="dynamic_rule" layout="vertical" style={{ maxWidth: 600 }}>
            <Form.Item
              name={`eventName`}
              label={`Event name`}
              rules={[{ required: true, message: 'Please input a name' }]}
            >
                <Input value={data.name}
                onChange={(e) => setData({...data, name:e.target.value})}
                placeholder="Event name" />
            </Form.Item>
            <Form.Item
              name={`eventHost`}
              label={`Event host`}
              rules={[{ required: true, message: 'Please input a host' }]}
            >
                <Input value={data.host}
                onChange={(e) => setData({...data, host:e.target.value})}
                placeholder="Event host" />
            </Form.Item>
            <Form.Item
              name={`eventDate`}
              label={`Event date`}
              rules={[{ required: true, message: 'Please input a date range' }]}
            >
                <RangePicker showTime
                onCalendarChange={onCalendarChange} />
            </Form.Item>
            <Form.Item
              name={`eventEndpoint`}
              label={`Url endpoint`}
              rules={[{ required: true, message: 'Please input an endpoint name' }]}
            >
                <Input value={data.endpoint}
                onChange={(e) => setData({...data, endpoint:e.target.value})}
                placeholder="Url endpoint" />
            </Form.Item>
            <Form.Item
              name={`eventArtworks`}
              label={`Event artworks`}
            >
                <ArtworkSelect data={data} setData={setData} />
            </Form.Item>
        </Form>
    </Modal>
  )
}

export default CreateModal