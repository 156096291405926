interface UserTypes {
    [key: number]: string;
}
  
export const USER_TYPES: UserTypes = {
    0: 'Root',
    1: 'Artist',
    2: 'Collector',
    3: 'Manager',
    4: 'Dealer',
    5: 'User',
    6: 'Support',
    7: 'Development',
    8: 'Client'
}