import React from "react";

import { useState } from "react";
import UserSelect from "../menu/Form/select/UserSelect";
import { App, Button, Col, Collapse, Divider, Flex, Input, Modal, Row, Select, Typography } from "antd";
import { ArtistRep } from "../../../data/models/artist-rep";
import { DeleteOutlined } from "@ant-design/icons";
import { makeRequest } from "../../helpers/axios-manipulator";
const { Text } = Typography;

type Props = {
    loading: boolean,
    contract: any,
    contracts: Array<ArtistRep>,
    setContracts: Function,
    hasNewContract: boolean,
    index: number,
    setHasNewContract: Function,
    totalUsedFee: number
}

const RepresentationContract = ({ loading, contract, contracts, setContracts, hasNewContract, index, setHasNewContract, totalUsedFee }: Props) => {
    const [dealerSelected, setDealerSelected] = useState<any>({ username: contract?.dealer_username, id: contract?.rep_id });
    const [unlinkContractModal, setUnlinkContractModal] = useState(false);
    const initialFee = contract?.fee || 0;
    const initialFreeFee = 100 - totalUsedFee + initialFee
    const [newContract, setNewContract] = useState<ArtistRep>(contract as ArtistRep)
    const [isOpen, setIsOpen] = useState(true);
    const [selectedType, setSelectedType] = useState(contract.rep_type.toString() || "4")
    const { message } = App.useApp();
    const contractTypes = [
        { label: "4 - artwork representation", value: "4" },
        { label: "3 - artist management", value: "3" }
    ]

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const saveContract = () => {
        const updatedContracts = contracts.map(contract => {
            if (contract.rep_id === dealerSelected.id || (hasNewContract && contract.id === "")) {
                return {
                    ...contract,
                    ...newContract,
                    rep_id: dealerSelected.id,
                    dealer_profile: dealerSelected.profile_name,
                    dealer_username: dealerSelected.username,
                    editing: false,
                    rep_type: parseInt(selectedType),
                };
            }
            return contract;
        });
        setContracts(updatedContracts);
        setHasNewContract(false);
    }
    const cancel = () => {
        let newContracts = [...contracts]

        newContracts[index].editing = false;
        if (hasNewContract) newContracts.splice(index, 1);
        setHasNewContract(false)
        setContracts([...newContracts])

    }
    const deleteContract = async () => {
        try {
            const contractsResponse = await makeRequest({
                origin: window.location.origin,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: '/api-update-contract',
                data: { ...contract, status: 'inactive' },
            });
            let newContracts = [...contracts]
            newContracts.splice(index, 1);
            setContracts([...newContracts])
        } catch (error) {
            message.error('something wrong happened while removing the contract.');
        }
    }
    const allowEdit = (e) => {
        e.stopPropagation();

        if (!isOpen) {
            setIsOpen(true);
        }
        let newContracts = [...contracts]
        newContracts[index].editing = true;
        setContracts([...newContracts])
    }

    const handleFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        const parsedValue = Math.max(0, Math.min(initialFreeFee, parseInt(value, 10) || 0));
        setNewContract({ ...newContract, fee: parsedValue });
    };

    return (
        <>
            {/* Delete contract Modal */}
            <Modal
                centered
                title="Unlink contract"
                open={unlinkContractModal}
                onOk={deleteContract}
                onCancel={() => setUnlinkContractModal(false)}
                okText="Unlink"
                cancelText="Cancel"
            >
                <p>Are you sure that you want to disable this contract from this user?</p>
            </Modal>
            <Collapse
                className="contract-collapse mt-3"
                activeKey={isOpen ? index : ''}
                defaultActiveKey={isOpen ? index : ''} onChange={toggleCollapse}
                collapsible='header'
                style={{ alignItems: 'center' }}
                items={[
                    {
                        key: index,
                        label: <Row gutter={8} style={{ alignItems: 'center' }}>
                            <Col flex="auto">
                                <Divider className="my-0" orientation="left" orientationMargin="0">{Object.keys(dealerSelected).length > 1 ? `${dealerSelected.username} ` : `Dealer ${index + 1}`}</Divider>
                            </Col>
                            {!contract.editing ?
                                <Col flex="116px">
                                    <Button
                                        className='mt-0'
                                        type="primary" onClick={allowEdit}>Edit {contract.rep_type === 4 ? 'Dealer' : 'Manager'}</Button>
                                </Col> :
                                <Button className="mr-3" onClick={() => setUnlinkContractModal(true)} type="primary" danger title="Delete contract">
                                    <DeleteOutlined />
                                </Button>
                            }
                        </Row>,
                        children:
                            <>
                                <UserSelect update={loading} className='mb-5' setData={setDealerSelected} data={dealerSelected} selectedUser={dealerSelected.username} route="/api-users?type=4" disableField={!contract.editing} />
                                {
                                    Object.keys(dealerSelected).length > 1 &&
                                    <>
                                        <Row gutter={8}>
                                            <Col span={24} md={12}>
                                                <Text>commission value %</Text>
                                                <Input
                                                    type="number"
                                                    min={0}
                                                    max={initialFreeFee}
                                                    disabled={(dealerSelected && Object.keys(dealerSelected).length === 0) || !contract.editing}
                                                    className='mb-5' value={newContract?.fee || ''}
                                                    onChange={handleFeeChange}
                                                    placeholder="Ex: 10" />
                                            </Col>
                                            <Col span={24} md={12}>
                                                <Text>Contract type</Text>
                                                <Select
                                                    disabled={!contract.editing}
                                                    placeholder="Select contract type"
                                                    style={{ width: '100%' }}
                                                    defaultValue={selectedType}
                                                    onChange={(e) => setSelectedType(e)}
                                                    options={contractTypes}
                                                />
                                            </Col>
                                        </Row>
                                        {contract?.editing &&
                                            <Flex gap="middle" align="start" vertical>
                                                <Flex className='w-full' justify="space-between" align="center">
                                                    <div>
                                                        <Button danger onClick={cancel}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    <Button
                                                        className='mt-4'
                                                        onClick={saveContract}
                                                        type="primary">Save contract</Button>
                                                </Flex>
                                            </Flex>
                                        }
                                    </>
                                }
                            </>
                        ,
                    },
                ]}
            />

        </>
    )
};


export default RepresentationContract;