import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd'
import CountUp from 'react-countup';
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import Loader from '../../components/Loader/Loader';
import React from 'react';

const formatter = (value: any) => <CountUp end={parseInt(value)} separator="," />;

const Home = () => {
  return (
    <Row gutter={16}>
      <Col span={12} className='mb-5'>
      <Statistic title="Active Users" value={112893} formatter={formatter} />
      </Col>
      <Col span={12} className='mb-5'>
        <Statistic title="Acc Balance (CNY)" value={112893} formatter={formatter} precision={2} />
      </Col>
      <Col span={24} md={12} className='mb-5'>
        <Card bordered={false}>
          <Statistic
            title="Active"
            value={11.28}
            precision={2}
            valueStyle={{ color: '#3f8600' }}
            prefix={<ArrowUpOutlined />}
            suffix="%"
          />
        </Card>
      </Col>
      <Col span={24} md={12} className='mb-5'>
        <Card bordered={false}>
          <Statistic
            title="Idle"
            value={9.3}
            precision={2}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ArrowDownOutlined />}
            suffix="%"
          />
        </Card>
      </Col>
      <Col span={24} md={12} className='mb-5'>
        <LineChart />
      </Col>
      <Col span={24} md={12} className='mb-5'>
        <BarChart />
      </Col>
    </Row>
  )
}

export default Home