import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SystemConfigState {
  theme: 'dark' | 'light';
  primaryColor: string;
  secondaryColor: string;
}

const initialState: SystemConfigState = {
  theme: 'light',
  primaryColor: '#15013A',
  secondaryColor: '#F7E5B9',
};

const systemConfigSlice = createSlice({
  name: 'systemConfig',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<'dark' | 'light'>) => {
      return { ...state, theme: action.payload };
    },
  },
});

export const { setTheme } = systemConfigSlice.actions;
export default systemConfigSlice.reducer;
