import styled from "styled-components";

export const PlacardsContainer = styled.div`
    position: relative;
`

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`