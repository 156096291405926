import React, { useState } from 'react';
import { User } from '../../data/models/user';
import { Alert, App, Button, Card, Form, Image, Input, Row, Col, Divider } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { setUser } from '../../redux/userSlicer';
import { useDispatch } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import srcImg from '../assets/images/blue-outline.svg'
import srcBcg from '../assets/images/login-background.png'
import { GoogleLogin } from '@react-oauth/google';
import { makeRequest } from '../helpers/axios-manipulator';
import {jwtDecode} from 'jwt-decode';

type loginProps = {
  onLogin: (userData: User) => void;
};

// Interface for decoded token
interface DecodedToken {
  aud: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  hd?: string;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  nbf: number;
  sub: string;
};

const GOOGLE_CLIENT_ID = '50589316056-40hajpoo0df074l7it6m7u0vqpug376p.apps.googleusercontent.com';

const Login: React.FC<loginProps> = ({ onLogin }) => {
  const isSmallScreen = window.innerWidth < 500;
  const { message } = App.useApp();
  const [notice, setNotice] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validate the token
  const validateToken = (decodedToken: DecodedToken) => {
    const expectedAudience = GOOGLE_CLIENT_ID;
    const expectedIssuer = "https://accounts.google.com";

    // Check token validity
    if (decodedToken.aud !== expectedAudience) {
      console.error("Invalid token audience!");
      return false;
    }

    if (decodedToken.iss !== expectedIssuer) {
      console.error("Invalid token issuer!");
      return false;
    }

    if (!decodedToken.email_verified) {
      console.error("Email not verified!");
      return false;
    }

    if (decodedToken.exp < Date.now() / 1000) {
      console.error("Token expired!");
      return false;
    }

    console.log("Token is valid!");
    return true;
  };

  const handleSuccess = async (credentialResponse) => {
    console.log('Google Login Success:', credentialResponse);
    // Send the credentialResponse to backend for verification
    try {
      const token = credentialResponse.credential;
      const decodedToken: DecodedToken = jwtDecode(token);
      console.log('Decoded Token:', decodedToken);
      console.log('aud (Audience):', decodedToken.aud);

      // Validate the token
      if( !validateToken(decodedToken) ) {
        message.error("Error validating Google token.")
        return;
      }

      const googleId = decodedToken.sub;
      
      /* TBD>>>>>>>>
      const response = await makeRequest({
        method: 'post',
        url: '/api-google-auth',
        headers: {},
        data: { token: credentialResponse.credential },
      });

      if (!response || !response.user) {
        throw new Error('Invalid authentication response');
      }

      // Get corresponding user and check if user has proper permissions
      const { googleId, email, name } = response.user;
      */
    
      // Fetch user and billing data
      const userInfo = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/getUserAndBillingByGoogleId/${googleId}`, // Use path param
        headers: {},
      });
    
      if (!userInfo) {
        throw new Error('User or billing data not found');
      }
    
      console.log('Authenticated User:', userInfo);

      Cookies.set('user', JSON.stringify(userInfo.user));
      dispatch(setUser(userInfo.user));
      message.success('Successfully authenticated with Google!');
      navigate(userInfo.user.id !== '0' && userInfo.user.profile_name !== 'Root' ? '/placards' : '/');
    } catch (error) {
      console.error('Error verifying token:', error);
      if( error?.response?.data ) {
        message.error(error?.response?.data, 5);
      } else {
        message.error("Error logging in to Business Admin.");
      }
    }
  };

  const handleError = () => {
    console.error('Google Login Failed');
    message.error("Failed to log in with Google.");
  };

  
  const onFinish = (values: any) => {
    if (!((values.username === 'admin' && values.password === '#ujamaa!2024') || 
      (values.username === 'business' && values.password === '123'))) {
      return setTimeout(() => {
        message.error('The combination of username and password is incorrect!', 5);
        //setNotice('The combination of username and password is incorrect!');
      }, 500);
    }
    const userData = { id: '0', profile_name: 'Root' } as User;
    
    Cookies.set('user', JSON.stringify(userData));

    dispatch(setUser(userData));
    
    message.success('User authenticated with success!');
    navigate('/');
  };

  return (
    <div style={{height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
    backgroundImage: `url(${srcBcg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
      <Card style={{ width: 350, height: 'fit-content', background: '#15013a',  border: 'none' }}>
        <Image preview={false} width={isSmallScreen? 300: 300} src={srcImg} />
        <Divider style={{width: "100%", borderTop: '1px solid gold'}} />
          {notice && <Alert style={{ marginBottom: 24 }} message={notice} type="error" showIcon closable />}
          <Form onFinish={onFinish}>
            <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
              <Input prefix={<UserOutlined />} placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ color: '#15013a', background: 'rgb(247 229 185)', width: '100%'}}>
                Login
              </Button>
            </Form.Item>
          </Form>

          <Divider style={{width: "100%", borderTop: '1px solid white'}} />

        <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center"}}>
      
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </div>
      </Card>
    </div>
  );
};;

export default Login;
