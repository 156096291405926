import { Alert, Avatar, Button, Modal, Card, Col, Flex, Row, Tag, Typography, Empty, Slider, InputNumber, notification, FloatButton, Select, App, Spin, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import UserSelect from '../../components/menu/Form/select/UserSelect'
import { User } from '../../../data/models/user'
import { Artwork } from '../../../data/models/artwork'
import { Placards } from '../../../data/models/placards'
import { DeleteOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined, MoreOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons'
import { USER_TYPES } from '../../../constants';
import { makeRequest } from '../../helpers/axios-manipulator'
import Meta from 'antd/es/card/Meta'
import * as S from './styles'
import Placard from '../../components/Placard'
import html2pdf from 'html2pdf.js';
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../redux/loadingSlicer'
import { RootState } from '../../../redux/appState'

const { Text, Title } = Typography;

const PlacardsScreen = () => {
  const [data, setData] = useState<User>({} as User);
  const [openModal, setOpenModal] = useState(false);
  const [openEmptyPlacardsModal, setOpenEmptyPlacardsModal] = useState(false);
  const [editPlacardModal, setEditPlacardModal] = useState(false);
  const [unlinkPlacardModal, setUnlinkPlacardModal] = useState(false);
  const [artworks, setArtworks] = useState([] as Artwork[])
  const [selectedArtworks, setSelectedArtworks] = useState([] as string[])
  const [availablePlacards, setAvailablePlacards] = useState([] as Placards[])
  const [times, setTimes] = useState(1)
  const [selectedEditArtwork, setSelectedEditArtwork] = useState({} as Artwork)
  const [selectedPlacard, setSelectedPlacard] = useState({})
  const [openFloatedBtn, setOpenFloatedBtn] = useState<{ [key: number]: boolean }>({})

  const [generatedLinkedPlacs, setGeneratedLinkedPlacs] = useState([] as Placards[])

  const placardRef = useRef(null);

  const { loading } = useSelector((state: RootState) => state.loading);

  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const user = useSelector((state: RootState) => state.user.user);

  const isRootUser = () => {
    return ( user && user?.id === '0' && user?.profile_name === 'Root');
  }

  const getArtworks = async (userId: string) => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/api-artwork-user/${userId}`,
        headers: {},
      });
      setArtworks(response)
      dispatch(setLoading(false));
    } catch (error) {
      console.error('Error fetching Artworks for placards:', error);
    }
  };

  const getArtworksForCurrentUser = async ( ) => {
    dispatch(setLoading(true));
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/getArtworksByRepId/${user.id}`,
        headers: {},
      });
      setArtworks(response);
    } catch (error) {
      console.error('Error fetching Artworks for placards:', error);
      message.error('Error fetching Artworks for placards', 5);
    }
    dispatch(setLoading(false));
  }

  function toggleStringInArray(array: string[], value: string) {
    return array.includes(value)
      ? array.filter(item => item !== value)
      : [...array, value];
  }

  const toggleArtwork = async (artId) => {
    setSelectedArtworks((prevState) => toggleStringInArray(prevState, artId))

  }

  const selectAll = () => {
    setSelectedArtworks(
      artworks
        .filter((art) => !art.hasOwnProperty('placard_id'))
        .map((art) => art.id)
    );
  }
  const deselectAll = () => {
    setSelectedArtworks([])
  }

  const downloadPlacardAsPdf = () => {
    const element = placardRef.current;
    if (!element) return;
    document.fonts.ready.then(() => {
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'placard.pdf',
        image: { type: 'svg', quality: 0.95 },
        html2canvas: {
          scale: 2,
          dpi: 300,
        },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'portrait'
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };

      html2pdf().from(element).set(options).save();
    });
  };

  const generatePlacards = async () => {
    dispatch(setLoading(true));
    let placards = [] as Placards[];
    selectedArtworks.forEach(async (e) => {
      try {
        const response = await makeRequest({
          origin: window.location.origin,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          url: '/api-generate-placard',
          data: { artworkId: e, isEnabled: true },
        });
        placards.push({ ...response })
        setGeneratedLinkedPlacs(placards)
        if (placards.length === selectedArtworks.length) {
          setOpenModal(true)
          dispatch(setLoading(false));
          getArtworks(data.id)
        }
      } catch (error) {
        console.error('Error generating placards:', error);
      }
    })
    setSelectedArtworks([])
  }

  const generateEmptyPlacards = async (exp = false) => {
    dispatch(setLoading(true));
    setOpenEmptyPlacardsModal(false)
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        url: '/api-generate-empty-placards',
        data: { times },
      });

      if (exp) {
        const modifiedArray = await response.generatedPlacards.map(item => ({
          code: item.generate_placards_ids
        }));
        setGeneratedLinkedPlacs(modifiedArray)
        setOpenModal(true)
        dispatch(setLoading(false));
      } else
        dispatch(setLoading(false));
    } catch (error) {
      console.error('Error generating empty placards:', error);
    }
    api.success({
      message: 'Success!',
      description:
        'Not assigned placards created!',
    });
  }

  const getAvailablePlacards = async () => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/api-available-placards`,
        headers: {},
      });
      setAvailablePlacards(response)
      dispatch(setLoading(false));
    } catch (error) {
      console.error('Error fetching available placards:', error);
    }
  }

  const unlinkPlacard = async (code) => {
    dispatch(setLoading(true));
    setUnlinkPlacardModal(false)
    try {
      const responseOld = await makeRequest({
        origin: window.location.origin,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/api-update-placard`,
        data: { placard: { code, is_enabled: false, artwork_id: null } },
      });
      setSelectedEditArtwork({} as Artwork)
      dispatch(setLoading(false));
      setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: !prev[selectedEditArtwork.id] }))
      api.success({
        message: 'Success!',
        description:
          `Placard ${code} unlinked with success!`,
      });

      if( isRootUser() ) {
        getArtworks(data.id);
      } else {
        getArtworksForCurrentUser();
      }


    } catch (error) {
      api.error({
        message: 'Error!',
        description:
          'something went wrong during the update',
      });
      dispatch(setLoading(false));
    }
  };

  const saveEditedPlacard = async () => {
    dispatch(setLoading(true));
    try {
      const responseOld = await makeRequest({
        origin: window.location.origin,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/api-update-placard`,
        data: { placard: { code: selectedEditArtwork.placard_id, is_enabled: false, artwork_id: null } },
      });
      const responseNew = await makeRequest({
        origin: window.location.origin,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/api-update-placard`,
        data: { placard: { ...selectedPlacard, artwork_id: selectedEditArtwork.id, is_enabled: true } },
      });
      api.success({
        message: 'Success!',
        description:
          `Placard ${selectedPlacard.code} updated with success!`,
      });
      setSelectedPlacard({})
      setSelectedArtworks([])
      setSelectedEditArtwork({} as Artwork)
      dispatch(setLoading(false));
      setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: false }))
      setEditPlacardModal(false);

      if( isRootUser() ) {
        getArtworks(data.id);
      } else {
        getArtworksForCurrentUser();
      }

    } catch (error) {
      api.error({
        message: 'Error!',
        description:
          'something went wrong during the update',
      });
      dispatch(setLoading(false));
    }
  }

  const closeModal = () => {
    setOpenModal(false)
    setSelectedEditArtwork({} as Artwork)
  }

  useEffect(() => {
    const userData = isRootUser() ? data : user;

    if (userData && Object.keys(userData).length > 0) {
      if( isRootUser() ) {
        getArtworks(userData.id);
      } else {
        getArtworksForCurrentUser();
      }
    }
  }, [data]);

  useEffect(() => {
    getAvailablePlacards()
  }, [selectedEditArtwork]);

  return (
    <S.PlacardsContainer>
      {selectedArtworks.length > 0 &&
        <h1>Quantity selected: {selectedArtworks.length}</h1>
      }
      {contextHolder}
      { isRootUser() ? (
        <>
      <Flex align="center" gap="middle" vertical>
        {(data && Object.keys(data).length > 0) ?
          <>
            <img style={{ objectFit: 'cover' }} width={80} height={80}
              src={data.profile_image_url || ''} alt={data.profile_image_url || data.username || ''}
            />
            <div>
              <Flex align='end' justify='center' gap="small">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Username:
                </Typography.Title>
                {data.username}
              </Flex>
              <Flex align='end' justify='center' gap="small">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Profile name:
                </Typography.Title>
                {data.profile_name}
              </Flex>
              <Flex align='end' justify='center' gap="small">
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Type:
                </Typography.Title>
                {USER_TYPES[data.type]}
              </Flex>
            </div>
          </> :
          <Avatar shape="square" size={100} icon={<UserOutlined />} />
        }
        <UserSelect update={loading} className='mb-5' setData={setData} data={data} width='300px' />
      </Flex>
        <Button size='large' type="primary" className='mb-3'
          onClick={() => setOpenEmptyPlacardsModal(true)}
        >Generate Empty placards</Button>
        </>
      ): (
        <div style={{display: "flex", margin: "auto", alignItems: "center", justifyContent: "center", textAlign: "center"}} >
        <Card title={user.display_name} style={{width: "200px"}}>
          <img src={user.profile_image_url} style={{ maxWidth: '100px', maxHeight: '100px' }} />
          <hr></hr>
          <span>{artworks.length} Artworks Found</span>

        </Card>
        </div>
      )}
      
      <Row gutter={[16, 24]}>
        {/* <S.Overlay> <Spin indicator={<LoadingOutlined spin />} size="large" /></S.Overlay> */}
        {artworks && Object.keys(artworks).length > 0 ?
          <>
            <Col span={24}>
              <Flex justify="space-between" gap="middle" >
                <div>
                  <Button type="primary" onClick={selectAll} style={{ marginRight: '8px', marginBottom: '.4rem', width: '100px' }}>Select All</Button>
                  {selectedArtworks.length > 0 &&
                    <Button style={{ width: '100px' }} onClick={deselectAll}>deselect all</Button>
                  }
                </div>
                <div>
                  {selectedArtworks.length === 1 &&
                    <Button size='large' type="primary" className='mr-2'
                      onClick={() => {
                        setSelectedEditArtwork(artworks?.find((art) => art.id === selectedArtworks[0]));
                        setEditPlacardModal(true)
                      }}
                    >Assign to placard</Button>
                  }
                  {isRootUser() && selectedArtworks.length > 0 &&
                    <Button size='large' type="primary"
                      onClick={() => { generatePlacards() }}
                    >Generate placards</Button>
                  }
                </div>
              </Flex>
            </Col>
            {artworks?.map((art) => (
              <Col className="gutter-row" span={24} md={12} lg={6} xl={4}>
                <Card
                  onClick={() => !art.placard_id && toggleArtwork(art.id)}
                  hoverable={!art.placard_id}
                  style={{ height: '100%', padding: "0.5rem" }}
                  cover={<img style={{ objectFit: 'cover', height: '130px' }} src={art.image_url || ''} alt={art.title || ''} />}
                >
                  <Meta title={art.title} />
                  <Text type={`${art.is_sale_enabled ? 'success' : 'warning'}`}>{art.is_sale_enabled ? 'Sale Enabled' : 'Not for Sale'}</Text>

                  {art.placard_id &&
                    <p className='my-0 flex'>
                      <Text strong>Placard Id: <Title level={5} style={{ margin: 0, display: 'contents' }}>{art.placard_id}</Title></Text>
                      {/* <MoreOutlined style={{fontSize: '18px', marginLeft: 'auto', cursor: 'pointer'}} /> */}
                      <>
                        <FloatButton.Group
                          className='styled-floated-button'
                          open={openFloatedBtn[art.id]}
                          trigger="click"
                          style={{ position: 'absolute', right: '-2px', bottom: '10px' }}
                          icon={<MoreOutlined />}
                          onClick={() => setOpenFloatedBtn((prev) => ({ ...prev, [art.id]: !prev[art.id] }))}
                        >
                          <FloatButton style={{ width: '30px', height: '30px' }}
                            onClick={() => {
                              setSelectedEditArtwork(art);
                              setUnlinkPlacardModal(true);
                              setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: !prev[selectedEditArtwork.id] }));
                            }}
                            icon={<DeleteOutlined />} />
                          <FloatButton style={{ width: '30px', height: '30px' }}
                            onClick={() => {
                              setSelectedEditArtwork(art);
                              setEditPlacardModal(true);
                              setOpenFloatedBtn((prev) => ({ ...prev, [selectedEditArtwork.id]: !prev[selectedEditArtwork.id] }))
                            }}
                            icon={<EditOutlined />} />
                        </FloatButton.Group>
                      </>
                    </p>
                  }
                </Card>
                {selectedArtworks.find((a) => a === art.id) &&
                  <div className={`flex flex-col m-auto absolute ${selectedArtworks.find((a) => a === art.id) && 'bg-overlay-500'} hover:bg-overlay-500 h-full top-0 rounded-lg z-10 cursor-pointer justify-center items-center desktop`}
                    onClick={() => toggleArtwork(art.id)} style={{ width: 'calc(100% - 16px)', left: '8px', borderRadius: '6px 6px 0 0', background: '#383838bf' }}>
                    <Alert
                      message="Selected"
                      type="success"
                      showIcon
                    />
                  </div>
                }
              </Col>
            ))}
          </>
          :
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Empty style={{ marginTop: '3rem' }} />
          </div>
        }
        {/* Placard modal */}
        <Modal
          title="Generated placards"
          style={{ maxWidth: '800px' }}
          centered
          maskClosable={false}
          onCancel={closeModal}
          open={openModal}
          footer={
            <Button type="primary"
              onClick={closeModal}
            >close</Button>
          }
          width={`100vw`}
        >
          <Flex justify='center' align='center'>
            <Button size='large' type="primary" className='mb-4'
              onClick={downloadPlacardAsPdf}
            >Download as PDF</Button>
          </Flex>
          <div style={{ overflow: 'auto', backgroundColor: 'transparent' }} ref={placardRef}>
            {generatedLinkedPlacs.length > 0 && generatedLinkedPlacs.map((newPlac) => (
              <Placard data={newPlac} />
            ))}
          </div>
          <Flex justify='center' align='center'>
            <Button size='large' type="primary" className='mt-4'
              onClick={downloadPlacardAsPdf}
            >Download as PDF</Button>
          </Flex>
        </Modal>
        {/* Empty Placard modal */}
        <Modal
          title="Generated empty placards"
          style={{ maxWidth: '800px' }}
          centered
          onCancel={() => setOpenEmptyPlacardsModal(false)}
          open={openEmptyPlacardsModal}
          footer={
            <>
              <Button onClick={() => setOpenEmptyPlacardsModal(false)}>Cancel</Button>
              <Button type="primary"
                onClick={() => { generateEmptyPlacards(false) }}
              >Generate placards</Button>
              <Button disabled={times > 30} type="primary"
                onClick={() => { generateEmptyPlacards(true) }}
              >Generate & export</Button>
            </>
          }
        >
          <div className='mt-4'>
            <Text strong>Number of placards</Text>
            <InputNumber className='mb-3' style={{ display: 'block', width: '126px' }} min={1} max={1000} defaultValue={times} value={times} onChange={(e) => setTimes(e)} />
          </div>
          <Slider defaultValue={times} min={1} max={1000} value={times} onChange={(e) => setTimes(e)} />
        </Modal>
        {/* Edit Placard modal */}
        {editPlacardModal &&
          <Modal
            centered
            title="Edit Placard"
            open={editPlacardModal}
            okText="Save"
            onOk={saveEditedPlacard}
            onCancel={() => { setEditPlacardModal(false) }}
            width={375}
          >
            {selectedEditArtwork.title}
            <Select
              showSearch
              placeholder="Select a placard"
              style={{ width: '100%' }}
              optionFilterProp="value"
              onChange={(e) => setSelectedPlacard(availablePlacards.find((item) => item.code === e))}
              options={availablePlacards.map((item) => ({ label: item.code, value: item.code }))}
            />
          </Modal>
        }
        {/* Delete placard Modal */}
        <Modal
          centered
          title="Unlink placard"
          open={unlinkPlacardModal}
          onOk={() => unlinkPlacard(selectedEditArtwork.placard_id)}
          onCancel={() => setUnlinkPlacardModal(false)}
          okText="Unlink"
          cancelText="Cancel"
        >
          <p>Are you sure that you want to unlink this artwork from the placard <b>{selectedEditArtwork.placard_id}</b></p>
        </Modal>
      </Row>
    </S.PlacardsContainer>
  )
}

export default PlacardsScreen
