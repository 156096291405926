import styled from "styled-components";

export const DealersContainer = styled.div`
    position: relative;
    height: 100%;
`

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`