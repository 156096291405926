import React from 'react';
import { Button, Input, QRCode } from 'antd';
import img from '../../assets/icons/logo-icon-white.png'
import { DownloadOutlined } from '@ant-design/icons';

const downloadQRCode = () => {
  const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL();
    const a = document.createElement('a');
    a.download = 'QRCode.png';
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const QRCodeGenerator: React.FC = () => {
    const [text, setText] = React.useState(process.env.REACT_APP_ENV === 'test' ? 'http://localhost:3000':process.env.REACT_APP_DOMAIN);

    return (
        <div id="myqrcode" style={{textAlign: 'center'}}>
            <QRCode style={{margin: 'auto'}} value={text || '-'}
            size={250}
            iconSize={250 / 4}
            icon={img} />
            <Input
            className='mt-4 mb-4'
                placeholder="-"
                maxLength={80}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Button type="primary" icon={<DownloadOutlined />} onClick={downloadQRCode}>
            Download
            </Button>
        </div>
    )
};


export default QRCodeGenerator;