import styled from "styled-components";

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0;
`
export const BoldText = styled.span`
  font-weight: bold;
`;

export const BlueText = styled.span`
  color: blue;
`;
