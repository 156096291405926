import React from 'react'
import spinner from '../../assets/gifs/spinner.svg'

const styles: React.CSSProperties = {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ffff',
}

const Loader = () => {
  return (
    <div style={styles}>
        <img src={spinner} alt='ujamaa loader' />
    </div>
  )
}

export default Loader